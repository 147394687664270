/* global config */

angular.module(config.app.name).directive('myTabs', function () {
    return {
        restrict: 'A',
        require: '^mdTabs',
        link: function (scope, element, attrs, mdTabsCtrl) {
            
            console.log('>>> scope')
            scope.getTabber(mdTabsCtrl)
            
        }
    };
});
